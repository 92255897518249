<template>
  <div style="height: calc(100vh - 48px); position:relative;">
    <Report/>
  </div>
</template>

<script>
import Report from "@/components/Report/Report";

export default {
  components: {Report}
}
</script>
