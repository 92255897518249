<template>
  <div style="height: 100%">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row class="mb-4">
        <v-col cols="12" sm="5" md="4" lg="3">
          <v-text-field
              :label="$t('report.search.label')"
              :placeholder="$t('report.search.placeholder')"
              v-model="search.search"
              solo
              clearable
              hide-details
              @click:clear="searchClear"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex ml-md-n5" cols="12" sm="5" md="4" lg="3">
          <v-select
              v-model="search.search_select"
              :items="search_from"
              label="Search from"
              :append-icon="'mdi-chevron-down'"
              solo
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2" md="4" lg="3" class="ml-auto d-flex justify-end">
          <v-btn
              style="padding: 24px 0 25px 0;"
              elevation="2"
              color="primary"
              medium
              class="ml-1"
              @click="arrayRevers"
          >
            <v-icon size="32">mdi-swap-vertical</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table fixed-header height="calc(100vh - 290px)">
            <template v-slot:default>
              <thead>
              <tr>
<!--                <th class="text-left">{{ $t('report.table.id') }}</th>-->
                <th class="text-left">{{ $t('report.table.date') }}</th>
                <th class="text-left">{{ $t('report.table.start_time') }}</th>
                <th class="text-left">{{ $t('report.table.end_time') }}</th>
                <th class="text-left">{{ $t('report.table.time') }}</th>
                <th class="text-left">{{ $t('report.table.employee') }}</th>
                <th class="text-left">{{ $t('report.table.caller_number') }}</th>
                <th class="text-left">{{ $t('report.table.called_number') }}</th>
                <th class="text-left">{{ $t('report.table.company_cn') }}</th>
                <th class="text-left">{{ $t('report.table.company_did') }}</th>
                <th class="text-left">{{ $t('report.table.category') }}</th>
                <th class="text-left">{{ $t('report.table.notice') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in searchItem" :key="item.id">
<!--                <td>{{ item.id }}</td>-->
                <td>{{ item.date }}</td>
                <td>{{ item.start_time }}</td>
                <td>{{ item.end_time }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.employee }}</td>
                <td>{{ isEmpty(item.caller_number) }}</td>
                <td>{{ isEmpty(item.called_number) }}</td>
                <td>{{ item.company_cn }}</td>
                <td>{{ item.company_did }}</td>
                <td>{{ item.category }}</td>
                <td>{{ isEmpty(item.notice) }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  props: {
    reports: {
      type: Array,
      require: true
    },
    companies: {
      type: Array,
      require: true
    },
    employees: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      preloader: true,
      reports_from_employee: [],
      companies_for_report: [],

      search: {
        search: '',
        search_select: 'caller_number'
      },
      search_from: [
        {
          text: this.$t('report.search.select.date'),
          value: 'date'
        },
        {
          text: this.$t('report.search.select.employee'),
          value: 'employee'
        },
        {
          text: this.$t('report.search.select.caller_number'),
          value: 'caller_number'
        },
        {
          text: this.$t('report.search.select.called_number'),
          value: 'called_number'
        },
        {
          text: this.$t('report.search.select.company_did'),
          value: 'company_did'
        },
        {
          text: this.$t('report.search.select.company_cn'),
          value: 'company_cn'
        },
        {
          text: this.$t('report.search.select.category'),
          value: 'category'
        }
      ],
    }
  },
  watch: {
    'search.search'() {
      this.search.search = this.search.search === null ? '' : this.search.search
    }
  },
  created () {
    this.getReportsFromEmployee()
  },
  computed: {
    searchItem() {
      return this.reports_from_employee.filter(report => {
        return report[this.search.search_select].toString().toLowerCase().indexOf(this.search.search.toLowerCase()) !== -1
      })
    }
  },
  methods: {
    arrayRevers() {
      this.reports_from_employee = this.reports_from_employee.reverse()
    },
    mxPreloader() {
      this.preloader = false
    },
    getReportsFromEmployee() {
      if (this.reports === undefined) {
        this.reports_from_employee = []
      } else {
        this.reports_from_employee = this.reports.map(report => {
          report.company_did = this.getReportCompany(report.company_id_did)
          report.company_cn = this.getReportCompany(report.company_id_cn)
          report.permissions = this.getReportEmployee(report.employee_id)
          return report
        })
      }
      setTimeout(this.mxPreloader, 300)
    },
    getReportCompany(company_id) {
      if (company_id === null || company_id === '') {
        return ' - '
      }

      return this.companies.find(company => company.id === company_id).company_name
    },
    getReportEmployee(employee_id) {
      const employee = this.employees.find(emploee => emploee.id === employee_id)
      return employee.last_name + ' ' + employee.first_name
    },
    isEmpty(item) {
      return item === null || item === '' ? ' - ' : item
    },
    searchClear() {
      this.search.search = ''
    }
  }
}
</script>
